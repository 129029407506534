import React, { useState } from "react";
// import CustomerView from "./CustomerView";
import CustomerList from "./CustomerList";
// import ReusableModal from "../General/ReusableModal";

import CustomerSearch from "./CustomerSearch";

const Customers = () => {
  const [searchfilters, setSearchFilters] = useState({});

  const handleCustomerSearch = (filters) => {
    setSearchFilters(filters);
  };

  return (
    <div className="customers-container">
      <div>
        <CustomerSearch onSearch={handleCustomerSearch} />
        <CustomerList searchfilters={searchfilters} />
      </div>
    </div>
  );
};

export default Customers;
