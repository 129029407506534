// src/components/StudentView.js
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";

import { fetchStudentByNumber, saveStudent } from "../../api/studentApi";
import { fetchClassesByStudentId } from "../../api/classApi";
import {
  fetchLegacyReportCardTemplates,
  fetchReport,
} from "../../api/reportApi";
import CustomerView from "../Customers/CustomerView";
import { findCustomerById } from "../../api/customerApi";
import { MdPersonSearch } from "react-icons/md";
import StudentDetailsSections from "./StudentDetailsSection";
import "./StudentView.css";
import { generateLegacyReportCardPDF } from "../../pdf/generateLegacyReportCard"; // Import the PDF generation function
import { generateReportPDF } from "../../pdf/generateReport"; // Import the PDF generation function

const StudentView = ({ studentNum, onClose, showParentLink = true }) => {
  const { userRole } = useContext(UserContext);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [studentData, setStudentData] = useState(null);
  const [classesData, setClassesData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [reportCardTemplates, setReportCardTemplates] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const handleBackToList = () => {
    setSelectedCustomer(null);
  };

  const handleCustomerSelect = (id) => {
    setSelectedCustomer(id);
    console.log(customerData);
  };

  useEffect(() => {
    const getStudent = async () => {
      try {
        const data = await fetchStudentByNumber(studentNum);
        setStudentData(data);
        console.log(data);
        const classes = await fetchClassesByStudentId(data._id);
        setClassesData(classes);
        const templates = await fetchLegacyReportCardTemplates();
        setReportCardTemplates(templates);
        const reports = await fetchReport(data._id);
        setReportData(reports);
        const customer = await findCustomerById(data.customer_num);
        console.log(customer);
        setCustomerData(customer);
      } catch (error) {
        setStudentData(null);
        setClassesData([]);
        setReportData([]);
      }
    };
    getStudent();
  }, [studentNum]);

  // Function to handle PDF generation
  const handleDownloadReportCard = (reportCardID) => {
    const reportCard = studentData.report_cards.find(
      (rc) => rc._id === reportCardID
    );
    const matchedTemplate = reportCardTemplates.find(
      (template) => template.course._id === reportCard.course._id
    );
    if (matchedTemplate) {
      generateLegacyReportCardPDF(reportCard, matchedTemplate); // Generate the PDF
    } else {
      console.error("No matching template found for the report card.");
    }
  };

  const handleDownloadNewReport = (reportCardID) => {
    const reportCard = reportData.find((rc) => rc._id === reportCardID);
    generateReportPDF(reportCard);
    console.log(reportCard);
  };

  // Function to handle saving the email (both for adding and editing)
  const handleEmailSave = async () => {
    if (!newEmail.trim()) return; // Prevent empty submissions

    const updatedStudent = { ...studentData, email: newEmail };

    const response = await saveStudent(updatedStudent);
    if (response.success) {
      setStudentData(updatedStudent);
      setNewEmail(updatedStudent.email);
      setIsEditingEmail(false);
    } else {
      console.error("Failed to save email:", response.error);
    }
  };

  const StudentDetails = () => {
    return (
      <div className="student-view-info">
        <div className="row">
          <div className="col-md-6 col-12">
            <h3>
              Student Name: {studentData.first_name} {studentData.last_name}
            </h3>
            <p className="next-recommended">
              <strong>Student Number:</strong> {studentData.student_num}
            </p>
            <p className="next-recommended">
              <strong>Email:</strong>{" "}
              {studentData.email && !isEditingEmail ? (
                <>
                  {studentData.email}{" "}
                  <button
                    onClick={() => {
                      setIsEditingEmail(true);
                      setNewEmail(studentData.email);
                    }}
                  >
                    Edit
                  </button>
                </>
              ) : (
                <>
                  <input
                    placeholder="Enter Email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                  <button onClick={handleEmailSave}>
                    {studentData.email ? "Save" : "Add Email"}
                  </button>
                  {studentData.email && (
                    <button onClick={() => setIsEditingEmail(false)}>
                      Cancel
                    </button>
                  )}
                </>
              )}
            </p>

            {userRole === 4 && customerData && customerData.CustomerID && (
              <p>
                <strong>Customer Number:</strong> {customerData.CustomerID}
              </p>
            )}

            {studentData.next_recommended_course &&
              studentData.next_recommended_course.length > 0 && (
                <p className="next-recommended">
                  <strong>Next Recommended Course: </strong>
                  {studentData.next_recommended_course[0].name}
                </p>
              )}
          </div>
          <ShowCustomerForAdmin />
        </div>
        <hr />
        <StudentDetailsSections
          studentData={studentData}
          classesData={classesData}
          reportData={reportData}
          handleDownloadNewReport={handleDownloadNewReport}
          handleDownloadReportCard={handleDownloadReportCard}
        />
      </div>
    );
  };

  const ShowCustomerForAdmin = () => {
    return (
      <>
        {" "}
        {userRole === 8 && (
          <div className="col-md-6 col-12">
            {customerData &&
              customerData.primary_first_name &&
              customerData.primary_last_name && (
                <div>
                  <h4>
                    Parent Name: {customerData.primary_first_name}{" "}
                    {customerData.primary_last_name}{" "}
                    {showParentLink && (
                      <MdPersonSearch
                        className="action-icon"
                        title="Open Customer Page"
                        onClick={() =>
                          handleCustomerSelect(customerData.CustomerID)
                        }
                      />
                    )}
                  </h4>
                </div>
              )}

            {customerData && customerData.CustomerID && (
              <p className="customer-item">
                <strong>Customer Number:</strong> {customerData.CustomerID}
              </p>
            )}

            {customerData && customerData.cell_phone && (
              <p className="customer-item">
                <strong>Customer Cell:</strong> {customerData.cell_phone}
              </p>
            )}
            {customerData && customerData.email_address && (
              <p className="customer-item">
                <strong>Customer Email:</strong> {customerData.email_address}
              </p>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="student-view-container">
      {studentData ? (
        <div>
          <button onClick={onClose}>Close</button>
          {selectedCustomer === null ? (
            <div>
              <hr></hr>
              {studentData && <StudentDetails />}
            </div>
          ) : (
            <div>
              <CustomerView customer={customerData} onBack={handleBackToList} />
            </div>
          )}
        </div>
      ) : (
        <div>
          <button onClick={onClose}>Close</button>
          <p>No student found.</p>
        </div>
      )}
    </div>
  );
};

export default StudentView;
