import { ClassNotes } from "./ClassNotes";
import React, { useState, useContext } from "react";
import ReusableModal from "../General/ReusableModal";
import StudentView from "../Students/StudentView";
import { UserContext } from "../../context/UserContext";
import { updateClassData } from "../../api/classApi";
import { FaPen, FaCheckCircle, FaRegEye } from "react-icons/fa";
import { toast } from "react-toastify";
import ReportCard from "../ReportCards/ReportCard";
import MidSeasonReportForm from "../ReportCards/MidSeasonReport";
import "./DisplayClass.css";

const DisplayClass = ({ classItem, onEdit, onRefreshClasses }) => {
  const [isNotesExpanded, setIsNotesExpanded] = useState(false);
  const { userRole } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const token = localStorage.getItem("token");

  const toggleNotes = () => {
    setIsNotesExpanded((prev) => !prev);
  };

  const handleAlreadySubmitted = (type) => {
    toast.info(`Already submitted ${type}`);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleViewStudentAdminClick = (studentItem) => {
    setModalContent(() => (
      <StudentView
        studentNum={studentItem.student.student_num}
        onClose={() => {
          handleModalClose();
        }}
      />
    ));
    setIsModalOpen(true);
  };

  const handleViewStudentInstructorClick = (studentItem) => {
    setModalContent(() => (
      <StudentView
        studentNum={studentItem.student.student_num}
        onClose={() => {
          handleModalClose();
        }}
      />
    ));
    setIsModalOpen(true);
  };

  const handleReportCardClick = (studentItem) => {
    setModalContent(() => (
      <ReportCard
        course={classItem.course}
        student={studentItem.student}
        classItem={classItem}
        onClose={() => {
          handleModalClose();
          onRefreshClasses(); // Refresh classes after submission
        }}
      />
    ));
    setIsModalOpen(true);
  };

  const handleMidSeasonUpdateClick = (studentItem) => {
    setModalContent(() => (
      <MidSeasonReportForm
        studentId={studentItem.student._id}
        classId={classItem._id}
        onClose={() => {
          handleModalClose();
          onRefreshClasses(); // Refresh classes after submission
        }}
      />
    ));
    setIsModalOpen(true);
  };

  // onAddNote will be called from ClassNotes with the new note object.
  const addNote = async (newNote) => {
    // Prepend the new note to the existing class_updates array.
    const updatedClassData = {
      ...classItem,
      class_updates: [newNote, ...(classItem.class_updates || [])],
    };

    try {
      await updateClassData(updatedClassData, token);
      // Refresh the class list (or update local state) to reflect changes.
      onRefreshClasses();
    } catch (error) {
      console.error("Error saving new note:", error);
    }
  };

  // Function to edit an existing note.
  const editNote = async (updatedNote) => {
    // updatedNote should have an _id property that identifies which note is being updated.
    const updatedClassData = {
      ...classItem,
      // Replace the note with the matching _id.
      class_updates: classItem.class_updates.map((note) =>
        note._id.toString() === updatedNote._id.toString()
          ? { ...note, ...updatedNote }
          : note
      ),
    };

    try {
      await updateClassData(updatedClassData, token);
      // Refresh the classes (or update local state) to reflect the edited note.
      onRefreshClasses();
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

  return (
    <div className="class-info">
      <div className="class-info-header">
        <p className="class-info-item">{classItem.class_code}</p>
        <p className="class-info-item">
          {classItem.instructor?.name || "Not Assigned"}
        </p>
        <p className="class-info-item">
          {classItem.course?.name || "Not Assigned"}
        </p>
        {userRole === 8 && (
          <button
            className="edit-icon-button"
            onClick={() => onEdit(classItem)}
          >
            <FaPen className="edit-icon" />
          </button>
        )}
      </div>
      <div className="class-details-container">
        <div className="details-section">
          <strong>Season:</strong> {classItem.season_ref.season}
          <br />
          <strong>Time:</strong> {classItem.from_time} - {classItem.to_time}
          <br />
          <strong>Start Date:</strong>{" "}
          {new Date(classItem.start_date).toISOString().split("T")[0]}
          <br />
          <strong>End Date:</strong>{" "}
          {new Date(classItem.end_date).toISOString().split("T")[0]}
          <br />
          <a href={classItem.course_link} target="_blank" rel="noreferrer">
            <strong>Course Link</strong>
          </a>{" "}
          -{" "}
          <a href={classItem.zoom_link} target="_blank" rel="noreferrer">
            <strong>Zoom Link</strong>
          </a>
        </div>
        <div className="students-section">
          {classItem.students && classItem.students.length > 0 ? (
            <table className="students-table">
              <thead>
                <tr>
                  <th>Student Information</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {classItem.students.map((studentItem, index) =>
                  studentItem?.student ? (
                    <tr key={`${studentItem.student._id}-${index}`}>
                      <td>
                        {studentItem.student.first_name}{" "}
                        {studentItem.student.last_name} (
                        {studentItem.student.student_num})
                      </td>
                      {userRole === 8 && (
                        <td>
                          <FaRegEye
                            className="action-icon"
                            title="View Student"
                            onClick={() =>
                              handleViewStudentAdminClick(studentItem)
                            }
                          />
                        </td>
                      )}
                      {userRole === 4 && (
                        <td>
                          {/* Report Card Action or Checkmark */}
                          {studentItem.submitted_reportcard ? (
                            <FaCheckCircle
                              className="checkmark-icon"
                              title="Report Card already submitted"
                              onClick={() =>
                                handleAlreadySubmitted("Report Card")
                              }
                            />
                          ) : (
                            <span
                              className="action-icon"
                              title="Report Card"
                              onClick={() => handleReportCardClick(studentItem)}
                            >
                              📑
                            </span>
                          )}

                          {/* Mid-Season Update Action or Checkmark */}
                          {studentItem.submitted_midseasonupdate ? (
                            <FaCheckCircle
                              className="checkmark-icon"
                              title="Mid-Season Update already submitted"
                              onClick={() =>
                                handleAlreadySubmitted("Mid-Season Update")
                              }
                            />
                          ) : (
                            <span
                              className="action-icon"
                              title="Mid-Season Update"
                              onClick={() =>
                                handleMidSeasonUpdateClick(studentItem)
                              }
                            >
                              📄
                            </span>
                          )}
                          <FaRegEye
                            className="action-icon"
                            title="View Student"
                            onClick={() =>
                              handleViewStudentInstructorClick(studentItem)
                            }
                          />
                        </td>
                      )}
                    </tr>
                  ) : (
                    <tr key={`missing-${index}`}>
                      <td>Student details not available</td>
                      <td>-</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          ) : (
            <p>No students enrolled</p>
          )}
        </div>
      </div>
      <ClassNotes
        toggleNotes={toggleNotes}
        isNotesExpanded={isNotesExpanded}
        classItem={classItem}
        onAddNote={addNote}
        onEditNote={editNote}
      />
      {modalContent != null && (
        <ReusableModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          content={modalContent}
        />
      )}
    </div>
  );
};

export default DisplayClass;
