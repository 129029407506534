import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { UserContext } from "../../context/UserContext";
import {
  fetchInstructors,
  fetchCourses,
  fetchClasses,
  updateClassData,
  searchStudents,
  deleteClass,
} from "../../api/classApi";
import { fetchSeasons } from "../../api/seasonApi";
import StudentSearchModal from "../Students/StudentSearchModal"; // Import the reusable modal component
import DisplayClass from "./DisplayClass";
import EditClassForm from "./EditClassForm";
import "./DisplayClasses.css";

const DisplayClasses = () => {
  const { userID, userRole } = useContext(UserContext);
  const [instructorsList, setInstructorsList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [classesList, setClassesList] = useState([]);
  const [seasonsList, setSeasonsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingClassId, setEditingClassId] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [newClassUpdate, setNewClassUpdate] = useState("");
  const [incompleteReports, setIncompleteReports] = useState([]);

  const [filter, setFilter] = useState({
    day: "",
    season: "Winter 2025",
    instructor: userRole === 4 ? userID : "",
  });

  const token = localStorage.getItem("token");

  // Update classes from server when filter or token changes
  const fetchAndUpdateClasses = useCallback(async () => {
    setLoading(true);
    try {
      // Pass the filter to the API
      const classes = await fetchClasses(token, filter);
      setClassesList(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  }, [token, filter]);

  // Fetch data on mount (and when token changes)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const instructors = await fetchInstructors(token);
        const courses = await fetchCourses(token);
        const seasons = await fetchSeasons(token);
        setSeasonsList(seasons);
        setInstructorsList(
          instructors.sort((a, b) => a.name.localeCompare(b.name))
        );
        setCoursesList(courses);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
    fetchAndUpdateClasses();
  }, [token, fetchAndUpdateClasses]);

  // Whenever filter changes, refetch classes
  useEffect(() => {
    fetchAndUpdateClasses();
  }, [filter, fetchAndUpdateClasses]);

  // Compute incompleteReports based on classesList
  useEffect(() => {
    const reports = [];
    classesList.forEach((classItem) => {
      classItem.students.forEach((studentRecord) => {
        if (!studentRecord.submitted_reportcard) {
          reports.push({
            classId: classItem._id,
            studentId: studentRecord.student,
          });
        }
      });
    });
    setIncompleteReports(reports);
  }, [classesList]);

  const applyFilter = useCallback(() => {
    const dayOrder = {
      M: 1, // Monday
      T: 2, // Tuesday
      W: 3, // Wednesday
      R: 4, // Thursday
      F: 5, // Friday
      S: 6, // Saturday
    };

    return classesList
      .filter((classItem) => {
        const dayMatches =
          filter.day === "" || classItem.class_code.startsWith(filter.day);
        const seasonMatches =
          filter.season === "" ||
          classItem.season.toLowerCase().includes(filter.season.toLowerCase());
        const instructorMatches =
          filter.instructor === "" ||
          classItem.instructor?._id === filter.instructor;
        return dayMatches && seasonMatches && instructorMatches;
      })
      .sort((a, b) => {
        // Extract day letters from the beginning of the class_code.
        const dayA = a.class_code.charAt(0).toUpperCase();
        const dayB = b.class_code.charAt(0).toUpperCase();
        const dayOrderA = dayOrder[dayA] || 999;
        const dayOrderB = dayOrder[dayB] || 999;
        if (dayOrderA !== dayOrderB) {
          return dayOrderA - dayOrderB;
        }

        // Extract the numeric part between the first and last characters.
        // Assumes format like: M1A, M12B, etc.
        const numA = parseInt(a.class_code.slice(1, -1), 10);
        const numB = parseInt(b.class_code.slice(1, -1), 10);
        if (numA !== numB) {
          return numA - numB;
        }

        // Finally, compare the last character.
        const lastA = a.class_code
          .charAt(a.class_code.length - 1)
          .toUpperCase();
        const lastB = b.class_code
          .charAt(b.class_code.length - 1)
          .toUpperCase();
        if (lastA < lastB) return -1;
        if (lastA > lastB) return 1;
        return 0;
      });
  }, [classesList, filter]);

  const filteredClasses = useMemo(() => applyFilter(), [applyFilter]);

  useEffect(() => {
    const reports = [];
    filteredClasses.forEach((classItem) => {
      classItem.students.forEach((studentRecord) => {
        // Check if the report card was not submitted.
        if (!studentRecord.submitted_reportcard) {
          reports.push({
            classId: classItem._id, // assuming _id is your unique identifier
            studentId: studentRecord.student,
          });
        }
      });
    });
    setIncompleteReports(reports);
  }, [filteredClasses]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    // Update only the specific filter; this won't reset instructor if season changes.
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle edit button click
  const handleEditClass = (classData) => {
    setEditingClassId(classData._id);
    setEditFormData({
      ...classData,
      season_ref: classData.season_ref || { _id: classData.season || "" },
      students: classData.students.map((student) => {
        return student.student && typeof student.student === "object"
          ? student
          : { student: { _id: student.student }, ...student }; // Populate with ID as fallback
      }),
      instructor: classData.instructor?._id || classData.instructor || "",
      course: classData.course?._id || classData.course || "",
      start_date: classData.start_date
        ? new Date(classData.start_date).toISOString().split("T")[0]
        : "",
      end_date: classData.end_date
        ? new Date(classData.end_date).toISOString().split("T")[0]
        : "",
    });
  };

  // Handle input change for the edit form
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => {
      if (name === "season_ref") {
        return {
          ...prev,
          season_ref: { _id: value },
        };
      }
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // Add student to the class
  const addStudentToClass = (student) => {
    console.log("Adding student:", student);
    console.log("EditFormData", editFormData);

    // Transform the student object into the class's students array object type
    const newStudentEntry = {
      student: {
        _id: student._id,
        student_num: student.student_num,
        first_name: student.first_name,
        last_name: student.last_name,
        // Add any other properties from the student model that you need
      },
      submitted_reportcard: false, // Default value
      submitted_midseasonupdate: false, // Default value
      withdrawn: false, // Default value
    };

    console.log("NewStudentEntry student:", newStudentEntry);
    setEditFormData({
      ...editFormData,
      students: [...editFormData.students, newStudentEntry],
    });

    setShowStudentModal(false);

    console.log(editFormData.students);
  };
  // Handle changes for class updates
  const handleAddClassUpdate = () => {
    if (newClassUpdate.trim() !== "") {
      const updateEntry = {
        update: newClassUpdate,
        date: new Date().toISOString(),
      };
      setEditFormData({
        ...editFormData,
        class_updates: [...(editFormData.class_updates || []), updateEntry],
      });
      setNewClassUpdate("");
    }
  };
  // Remove student from the class
  const removeStudentFromClass = (studentId) => {
    setEditFormData({
      ...editFormData,
      students: editFormData.students.filter(
        (studentItem) => studentItem.student._id !== studentId
      ),
    });
  };
  const removeClass = async (classID) => {
    try {
      const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
      if (!token) {
        console.error("Token not found, unable to authenticate request");
        return;
      }

      const confirmDelete = window.confirm(
        "Are you sure you want to delete this class?"
      );
      if (!confirmDelete) {
        return; // Exit if the user cancels the action
      }

      // Call the API to delete the class
      await deleteClass(classID, token);

      // Optionally, update your UI after deletion
      console.log(`Class with ID ${classID} has been deleted`);
      setClassesList((prevClasses) =>
        prevClasses.filter((classItem) => classItem._id !== classID)
      );
    } catch (error) {
      console.error("Error removing class:", error);
      alert("Failed to delete the class. Please try again.");
    }
  };
  // Handle saving the edited class
  const handleSaveClass = async (e) => {
    e.preventDefault();
    console.log(editFormData);
    //const normalizedStudents = normalizeStudentsData(editFormData.students);
    try {
      await updateClassData(
        { ...editFormData, students: editFormData.students },
        token
      );
      const updatedClasses = await fetchClasses(token);
      setClassesList(updatedClasses);
      setEditingClassId(null);
    } catch (error) {
      console.error("Error updating class: ", error);
    }
  };

  if (loading) {
    return (
      <div className="display-classes">
        <h2>Loading classes...</h2>
      </div>
    );
  }

  return (
    <div className="display-classes">
      <h2>Classes List</h2>

      {/* Filter Section */}
      <div className="filter-section">
        {/* <div className="filter-item">
          <label htmlFor="filter-title">Filter By --- </label>
        </div> */}

        <div className="row">
          <div className="filter-item col 2">
            <p>Outstanding Report Cards: {incompleteReports.length}</p>
          </div>
          <div className="filter-title col-2 text-end">Filter By: </div>
          <div className="filter-item col-2">
            <label htmlFor="day">Day:</label>
            <select
              id="day"
              name="day"
              value={filter.day}
              onChange={handleFilterChange}
            >
              <option value="">All Days</option>
              <option value="M">Monday</option>
              <option value="T">Tuesday</option>
              <option value="W">Wednesday</option>
              <option value="R">Thursday</option>
              <option value="F">Friday</option>
              <option value="S">Saturday</option>
            </select>
          </div>
          <div className="filter-item col-3">
            <label htmlFor="instructor">Instructor:</label>
            <select
              id="instructor"
              name="instructor"
              value={filter.instructor}
              onChange={handleFilterChange}
            >
              <option value="">All Instructors</option>
              {instructorsList.map((instructor) => (
                <option key={instructor._id} value={instructor._id}>
                  {instructor.name}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-item col-3">
            <label htmlFor="season">Season:</label>
            <select
              id="season"
              name="season"
              value={filter.season}
              onChange={handleFilterChange}
            >
              <option value="">All Seasons</option>
              <option value="Winter 2025">Winter 2025</option>
              <option value="2024-25">2024-25</option>
            </select>
          </div>
        </div>
      </div>
      {/* FILTERING ENDS */}
      <hr></hr>
      {filteredClasses.length === 0 ? (
        <p>No classes available</p>
      ) : (
        <ul className="classes-list">
          {filteredClasses.map((classItem) => (
            <li key={classItem._id} className="class-item">
              {editingClassId === classItem._id ? (
                <EditClassForm
                  editFormData={editFormData}
                  instructorsList={instructorsList}
                  coursesList={coursesList}
                  seasonsList={seasonsList}
                  handleEditInputChange={handleEditInputChange}
                  handleSaveClass={handleSaveClass}
                  handleAddClassUpdate={handleAddClassUpdate}
                  removeStudentFromClass={removeStudentFromClass}
                  addStudentToClass={addStudentToClass}
                  removeClass={removeClass}
                  newClassUpdate={newClassUpdate}
                  setNewClassUpdate={setNewClassUpdate}
                  setShowStudentModal={setShowStudentModal}
                  setEditingClassId={setEditingClassId}
                />
              ) : (
                <DisplayClass
                  classItem={classItem}
                  onEdit={handleEditClass}
                  onRefreshClasses={fetchAndUpdateClasses}
                />
              )}
            </li>
          ))}
        </ul>
      )}

      {showStudentModal && (
        <StudentSearchModal
          onClose={() => setShowStudentModal(false)}
          onAddStudent={addStudentToClass}
          searchStudents={searchStudents}
        />
      )}
    </div>
  );
};

export default DisplayClasses;
