// src/components/CustomerList.js
import React, { useEffect, useState, useCallback } from "react";
import { fetchCustomersWithPagination } from "../../api/customerApi";
import ReusableModal from "../General/ReusableModal";
import CustomerForm from "./CustomerForm";
import CustomerView from "./CustomerView";
import "./CustomerList.css"; // CSS specific to this component
const API_URL = process.env.REACT_APP_API_URL;

const CustomerList = ({ searchfilters }) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const limit = 25; // Number of customers per page

  const fetchCustomers = useCallback(
    async (page = currentPage) => {
      const filters = {
        customerName: "",
        customerLastName: "",
        customerEmail: "",
        customerId: "",
        customerPhone: "",
        ...searchfilters, // Override defaults with incoming filters
      };
      setLoading(true);
      try {
        const response = await fetchCustomersWithPagination(
          page,
          limit,
          filters
        );
        setCustomers(response.customers || []);
        setTotalPages(response.totalPages || 1);
        setError(null); // Clear any previous error
      } catch (err) {
        setError(err.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    },
    [searchfilters, limit, currentPage] // Dependencies for fetchCustomers
  );

  useEffect(() => {
    if (!isModalOpen) {
      fetchCustomers();
    }
  }, [fetchCustomers, isModalOpen]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAddCustomer = () => {
    let highestCustomerID = 0;
    if (customers.length > 0) {
      highestCustomerID = Math.max(
        ...customers.map((customer) => customer.CustomerID)
      );
    }
    setModalContent(
      <CustomerForm
        onSubmit={handleFormSubmit}
        onClose={handleModalClose}
        nextCustomerID={highestCustomerID + 1}
      />
    );
    setIsModalOpen(true);
  };

  const handleEditCustomer = (customer) => {
    setModalContent(
      <CustomerForm
        customer={customer}
        onSubmit={handleFormSubmit}
        onClose={handleModalClose}
        onDelete={handleDeleteCustomer}
      />
    );
    setIsModalOpen(true);
  };
  const handleDeleteCustomer = async (customerId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_URL}/api/customers/${customerId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete customer");
      }

      setCurrentPage(1); // Refresh the customer list
      return { success: true };
    } catch (error) {
      console.error("Error deleting customer: ", error);
      return { success: false, error: error.message };
    }
  };
  const handleFormSubmit = async (formData, customer) => {
    try {
      const token = localStorage.getItem("token");
      const method = customer ? "PUT" : "POST";
      const endpoint = customer
        ? `${API_URL}/api/customers/${customer.CustomerID}`
        : `${API_URL}/api/customers`;

      const response = await fetch(endpoint, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        if (response.status === 409)
          return {
            success: false,
            error: "Customer with same ID already exists!",
          };
        else throw new Error("Failed to save customer");
      }

      setCurrentPage(1); // Refresh the customer list
      return { success: true }; // Return success status
    } catch (error) {
      return { success: false, error: error.message }; // Return failure status with error message
    }
  };

  const handleCustomerSelect = (customer) => {
    setModalContent(() => (
      <CustomerView
        customer={customer}
        onEdit={handleEditCustomer}
        onBack={() => {
          handleModalClose();
        }}
      />
    ));
    setIsModalOpen(true);
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="customer-list-container">
      <button
        className="btn btn-primary add-customer"
        onClick={handleAddCustomer}
      >
        Add Customer
      </button>
      <hr></hr>
      <h2>Customer List</h2>

      <div className="customer-list-pagination">
        <button
          className="previous-button"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        {/* First Page */}
        <button
          className={
            currentPage === 1 ? "active-page page-number" : "page-number"
          }
          onClick={() => handlePageChange(1)}
        >
          1
        </button>

        {/* Ellipsis if necessary */}
        {currentPage > 51 && <span>...</span>}

        {/* Centered Pages */}
        {Array.from({ length: 5 }) // Generate 5 surrounding pages
          .map((_, index) => currentPage - 50 + index * 25) // Calculate pages: -50, -25, currentPage, +25, +50
          .filter((page) => page > 1 && page < totalPages) // Filter valid pages
          .map((page) => (
            <button
              key={page}
              className={
                page === currentPage ? "active-page page-number" : "page-number"
              }
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ))}

        {/* Ellipsis if necessary */}
        {currentPage < totalPages - 50 && <span>...</span>}

        {/* Last Page */}
        <button
          className={
            currentPage === totalPages
              ? "active-page page-number"
              : "page-number"
          }
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </button>
        <button
          className="next-button"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
      <br></br>
      <table className="customer-list-table">
        <thead>
          <tr>
            <th>Customer ID</th>
            <th>Primary Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => (
            <tr key={customer.CustomerID}>
              <td>{customer.CustomerID}</td>
              <td>
                {customer.primary_first_name} {customer.primary_last_name}
              </td>
              <td>{customer.email_address}</td>
              <td>
                <button
                  className="customer-list-select-button"
                  onClick={() => handleCustomerSelect(customer)}
                >
                  View
                </button>
                <button
                  className="customer-list-edit-button"
                  onClick={() => handleEditCustomer(customer)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="customer-list-pagination">
        <button
          className="previous-button"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        {/* First Page */}
        <button
          className={
            currentPage === 1 ? "active-page page-number" : "page-number"
          }
          onClick={() => handlePageChange(1)}
        >
          1
        </button>

        {/* Ellipsis if necessary */}
        {currentPage > 51 && <span>...</span>}

        {/* Centered Pages */}
        {Array.from({ length: 5 }) // Generate 5 surrounding pages
          .map((_, index) => currentPage - 50 + index * 25) // Calculate pages: -50, -25, currentPage, +25, +50
          .filter((page) => page > 1 && page < totalPages) // Filter valid pages
          .map((page) => (
            <button
              key={page}
              className={
                page === currentPage ? "active-page page-number" : "page-number"
              }
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ))}

        {/* Ellipsis if necessary */}
        {currentPage < totalPages - 50 && <span>...</span>}

        {/* Last Page */}
        <button
          className={
            currentPage === totalPages
              ? "active-page page-number"
              : "page-number"
          }
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </button>
        <button
          className="next-previous"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>

      {/* Reusable Modal to create or edit a customer */}
      <ReusableModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        content={modalContent}
      />
    </div>
  );
};

export default CustomerList;
