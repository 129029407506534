import React, { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import ClassNoteRow from "./ClassNoteRow";
import "./ClassNotes.css";

export function ClassNotes({
  toggleNotes,
  isNotesExpanded,
  classItem,
  onAddNote,
  onEditNote,
}) {
  const { userID } = useContext(UserContext);
  const [isEditingNewNote, setIsEditingNewNote] = useState(false);
  const [newNoteText, setNewNoteText] = useState("");

  const handleAddNoteClick = () => {
    setIsEditingNewNote(true);
  };

  // When Save is clicked, create the new note object and call the callback
  const handleSaveNewNote = () => {
    if (!newNoteText.trim()) return; // Avoid saving an empty note
    const newNote = {
      update: newNoteText,
      date: new Date().toISOString(),
      author: userID,
    };
    onAddNote(newNote);
    setIsEditingNewNote(false);
    setNewNoteText("");
  };

  const handleCancelNewNote = () => {
    setIsEditingNewNote(false);
    setNewNoteText("");
  };

  return (
    <div className="notes-section">
      <strong className="expandable-title" onClick={toggleNotes}>
        Class Notes {isNotesExpanded ? "▲" : "▼"}
      </strong>
      {isNotesExpanded && (
        <>
          {/* Container to right-align "Add Note" */}
          <div className="add-note-container">
            <strong className="add-note" onClick={handleAddNoteClick}>
              Add Note
            </strong>
          </div>
          {/* New note form */}
          {isEditingNewNote && (
            <div className="new-note-form">
              <textarea
                value={newNoteText}
                onChange={(e) => setNewNoteText(e.target.value)}
                placeholder="Enter your note here"
              />
              <div className="new-note-actions">
                <button onClick={handleSaveNewNote}>Save</button>
                <button onClick={handleCancelNewNote}>Cancel</button>
              </div>
            </div>
          )}
          {classItem.class_updates && classItem.class_updates.length > 0 ? (
            <ul>
              {classItem.class_updates
                .slice() // Copy to avoid mutating original array
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((classUpdate, index) => (
                  <li key={`${classUpdate.date}-${index}`}>
                    <ClassNoteRow
                      classUpdate={classUpdate}
                      userID={userID}
                      handleEditNote={onEditNote}
                    />
                    {/* <div className="row">
                      <div
                        className="col-2"
                        style={{
                          borderStyle: "solid",
                          borderRadius: "15px",
                          borderColor: "#D3E1E3",
                          borderWidth: "1px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          minHeight: "50px",
                          maxHeight: "100px",
                          alignSelf: "center",
                          backgroundColor: "#F0F8FF",
                        }}
                      >
                        <strong
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {classUpdate.author.name}
                        </strong>
                        <span
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {
                            new Date(classUpdate.date)
                              .toISOString()
                              .split("T")[0]
                          }
                        </span>
                      </div>

                      <div
                        className="col-10"
                        style={{
                          borderStyle: "solid",
                          borderRadius: "15px",
                          borderColor: "#D3E1E3",
                          backgroundColor: "rgb(243, 249, 255)",
                          borderWidth: "1px 0 1px 2px",
                          fontSize: "14px",
                          paddingTop: "5px",
                          paddingBottom: "7px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {classUpdate.update}
                      </div>
                    </div> */}
                    <hr
                      style={{
                        borderColor: "#212121",
                        borderWidth: "1px",
                        borderStyle: "dashed",
                        marginTop: "7px",
                        marginBottom: "7px",
                      }}
                    />
                  </li>
                ))}
            </ul>
          ) : (
            <p>No notes have been added.</p>
          )}
        </>
      )}
    </div>
  );
}

export default ClassNotes;
