import React, { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

const StudentDetailsSections = ({
  studentData,
  classesData,
  reportData,
  handleDownloadReportCard,
  handleDownloadNewReport,
}) => {
  const [showStudentComments, setShowStudentComments] = useState(false);
  const [showCoursesTaken, setShowCoursesTaken] = useState(false);
  const [showReportCards, setShowReportCards] = useState(false);

  return (
    <>
      {/* Student Comments Section */}
      <div className="student-comments">
        <h4
          onClick={() => setShowStudentComments((prev) => !prev)}
          style={{ cursor: "pointer", userSelect: "none" }}
        >
          {showStudentComments ? <FaChevronDown /> : <FaChevronRight />} Student
          Comments
        </h4>
        {showStudentComments &&
          (studentData.student_comments &&
          studentData.student_comments.length > 0 ? (
            <ul>
              {studentData.student_comments
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((comment, index) => (
                  <li key={index}>
                    <div className="row">
                      <div className="col-2">
                        <strong>{comment.author.name}</strong>
                      </div>
                      <div className="col-8">{comment.comment}</div>
                      <div className="col-2">
                        {new Date(comment.date).toLocaleDateString()}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          ) : (
            <p>No comments found for this student.</p>
          ))}
      </div>

      {/* Courses Taken Section */}
      <div className="courses-taken">
        <h4
          onClick={() => setShowCoursesTaken((prev) => !prev)}
          style={{ cursor: "pointer", userSelect: "none" }}
        >
          {showCoursesTaken ? <FaChevronDown /> : <FaChevronRight />} Classes
        </h4>
        {showCoursesTaken &&
          (classesData && classesData.length > 0 ? (
            <ul>
              {classesData
                .sort((a, b) => {
                  if (a.season > b.season) return -1;
                  if (a.season < b.season) return 1;
                  return 0;
                })
                .map((courseClass, index) => (
                  <li key={index}>
                    <div className="row justify-content-evenly">
                      <div className="col-md-4 col-12">
                        <strong>Course Name:</strong>{" "}
                        {courseClass.course?.name || "Not Assigned"}
                      </div>
                      <div className="col-md-4 col-12">
                        <strong>Instructor:</strong>{" "}
                        {courseClass.instructor?.name || "Not Assigned"}
                      </div>
                      <div className="col-md-3 col-12">
                        <strong>Season:</strong>{" "}
                        {courseClass.season || "Not Assigned"}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          ) : (
            <p>No classes found for this student.</p>
          ))}
      </div>

      {/* Report Cards Section */}
      <div className="instructor-comments">
        <h4
          onClick={() => setShowReportCards((prev) => !prev)}
          style={{ cursor: "pointer", userSelect: "none" }}
        >
          {showReportCards ? <FaChevronDown /> : <FaChevronRight />} Report
          Cards
        </h4>
        {showReportCards &&
          ((studentData.report_cards && studentData.report_cards.length > 0) ||
          (reportData && reportData.length > 0) ? (
            <ul>
              {[
                // Normalize legacy report cards
                ...(studentData.report_cards || []).map((legacyReport) => ({
                  type: "legacy",
                  id: legacyReport._id,
                  report_date: legacyReport.report_date,
                  course_name: legacyReport.course.name,
                  instructor_name: legacyReport.instructor_name,
                  comments: legacyReport.instructor_comments,
                  next_recommended: legacyReport.next_recommended,
                  status: "Completed",
                })),
                // Normalize new reports
                ...(reportData || []).map((newReport) => ({
                  type: "new",
                  id: newReport._id,
                  report_date: newReport.date_created,
                  course_name: newReport.class?.course?.name || "N/A",
                  instructor_name: newReport.instructor?.name || "N/A",
                  comments: newReport.instructor_comment,
                  next_recommended:
                    newReport.next_recommended_course?.name || "N/A",
                  status: newReport.status,
                })),
              ]
                .sort(
                  (a, b) => new Date(b.report_date) - new Date(a.report_date)
                )
                .map((report, index) => (
                  <li key={index}>
                    <div className="course-container">
                      <div className="course-info">
                        <b>Course:</b> {report.course_name}
                        <br />
                        <b>Instructor:</b> {report.instructor_name}
                        <br />
                        <b>
                          Report Date:{" "}
                          {new Date(report.report_date).toLocaleDateString()}
                        </b>
                      </div>
                      <div className="report-item">
                        {["Ready", "Completed"].includes(report.status) ? (
                          <button
                            className="download-report-button"
                            onClick={() =>
                              report.type === "legacy"
                                ? handleDownloadReportCard(report.id)
                                : handleDownloadNewReport(report.id)
                            }
                          >
                            Download Report Card
                          </button>
                        ) : (
                          <span className="report-status-text">
                            {report.status === "Draft"
                              ? "Draft - Not ready for download"
                              : "Reviewing - Pending approval"}
                          </span>
                        )}
                      </div>
                    </div>
                    <hr />
                    {report.comments || "No comments provided"}
                    <br />
                  </li>
                ))}
            </ul>
          ) : (
            <p>No reports found for this student.</p>
          ))}
      </div>
    </>
  );
};

export default StudentDetailsSections;
