// src/components/HomePage.js
import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import DisplayClasses from "../Classes/DisplayClasses";

const HomePage = () => {
  const { userRole, userID } = useContext(UserContext);
  //const token = localStorage.getItem("token");

  // Render content based on the user role
  const renderContentForRole = () => {
    switch (userRole) {
      case 8:
        return (
          <div>
            <h2>Admin Dashboard</h2>
            <p>Welcome, Admin! Here are your admin options.</p>
          </div>
        );
      case 4:
        return (
          <div>
            <h2>Instructor Dashboard</h2>
            <DisplayClasses instructorID={userID} userRole={userRole} />
          </div>
        );
      default:
        return (
          <div>
            <h2>Welcome to Our Application</h2>
            <p>Please log in to continue.</p>
          </div>
        );
    }
  };

  return <div className="home-page">{renderContentForRole()}</div>;
};

export default HomePage;
