// src/components/StudentSearch.js
import React, { useState } from "react";
import { fetchStudentByName } from "../../api/studentApi";
import StudentView from "./StudentView";
import "./StudentSearch.css";
import StudentSearchResults from "./StudentSearchResults";

const StudentSearch = () => {
  const [studentNum, setStudentNum] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [studentsFound, setStudentsFound] = useState([]);
  const [search, setSearch] = useState(false);
  const [error, setError] = useState("");

  const handleSearch = async () => {
    try {
      setError("");
      if (studentNum) {
        setSearch(true);
        setStudentsFound([]);
      } else if (firstName || lastName) {
        const students = await fetchStudentByName(firstName, lastName);
        console.log(students);
        setStudentsFound(students);
      } else {
        setError("Please enter student number or name to search.");
      }
    } catch (error) {
      setError(error.message);
      setSearch(false);
      setStudentsFound([]);
    }
  };

  const onStudentSelect = (studentNumber) => {
    setStudentNum(studentNumber);
    setSearch(true);
  };

  return (
    <div className="student-search-container">
      {!search && (
        <div>
          <h2 className="student-search">Student Search</h2>
          <hr></hr>
          <div className="row align-items-center">
            <div className="col-4 text-center">
              <h5>Student Number:</h5>
            </div>
            <div className="col-4">
              <input
                type="text"
                placeholder="Enter Student Number"
                value={studentNum}
                onChange={(e) => setStudentNum(e.target.value)}
                className="student-search-input"
              />
            </div>
            <div className="col-4">
              <p>
                Student number will take priority, leave blank to search by name
                instead.
              </p>
            </div>
          </div>
          <hr></hr>
          <div className="row align-items-center">
            <div className="col-3 text-center">
              <strong>First Name:</strong>
            </div>
            <div className="col-3">
              <input
                type="text"
                placeholder="Enter First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="student-search-input"
              />
            </div>
            <div className="col-3 text-center">
              <strong>Last Name:</strong>
            </div>
            <div className="col-3">
              <input
                type="text"
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="student-search-input"
              />
            </div>
          </div>
          <hr></hr>
          <button className="student-search-button" onClick={handleSearch}>
            Search
          </button>
          <hr />
        </div>
      )}

      {error && <p className="error-message">{error}</p>}
      {search && (
        <StudentView
          studentNum={studentNum}
          onClose={() => {
            setSearch(false);
            setStudentNum("");
          }}
        />
      )}
      {studentsFound && !search && (
        <StudentSearchResults
          students={studentsFound}
          onStudentSelect={onStudentSelect}
        />
      )}
    </div>
  );
};

export default StudentSearch;
