// api/studentApi.js
const API_URL = process.env.REACT_APP_API_URL;

export const fetchStudents = async (page, limit) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token is missing");
    }

    const response = await fetch(
      `${API_URL}/api/students?page=${page}&limit=${limit}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch students");
    }

    const data = await response.json();
    return data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching students:", error);
    throw error; // Throw error so it can be caught by the caller
  }
};

// Fetch a student by student number
export const fetchStudentByNumber = async (studentNum) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token is missing");
    }

    const response = await fetch(
      `${API_URL}/api/students/find?student_num=${studentNum}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Student not found");
    }

    const data = await response.json();
    return data[0]; // Assuming we expect only one result, return the first student in the array
  } catch (error) {
    console.error("Error fetching student:", error);
    throw error;
  }
};

// Fetch a students by first and last name
export const fetchStudentByName = async (first_name, last_name) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token is missing");
    }

    const response = await fetch(
      `${API_URL}/api/students/find?first_name=${first_name}&last_name=${last_name}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Student not found");
    }

    const data = await response.json();
    return data; // Assuming we expect only one result, return the first student in the array
  } catch (error) {
    console.error("Error fetching student:", error);
    throw error;
  }
};

// Fetch students by customer number
export const fetchStudentsByCustomerNum = async (customerNum) => {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${API_URL}/api/students/find?customer_num=${customerNum}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      if (response.status === 404) {
        console.log("no students found");
        return []; // No students found for this customer
      }
      throw new Error("Failed to fetch students");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching students:", error);
    throw error;
  }
};

// Fetch students by customer number
export const fetchStudentsByID = async (_id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${API_URL}/api/students/find?_id=${_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      if (response.status === 404) {
        return []; // No students found for this customer
      }
      throw new Error("Failed to fetch students");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching students:", error);
    throw error;
  }
};

export const saveStudent = async (studentData) => {
  try {
    const token = localStorage.getItem("token");
    const method = studentData._id ? "PUT" : "POST";
    const endpoint = `${API_URL}/api/students`;

    const response = await fetch(endpoint, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(studentData),
    });

    if (!response.ok) {
      throw new Error("Failed to save student");
    }

    const data = await response.json();
    return { success: true, data };
  } catch (error) {
    console.error("Error saving student:", error);
    return { success: false, error: error.message };
  }
};
