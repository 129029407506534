// src/components/SeasonManagement.js
import React, { useEffect, useState } from "react";
import { fetchSeasons, createSeason, updateSeason } from "../../api/seasonApi";
import "./SeasonManagement.css";

const SeasonManagement = () => {
  const [seasons, setSeasons] = useState([]);
  const [newSeason, setNewSeason] = useState("");
  const [editingSeasonId, setEditingSeasonId] = useState(null);
  const [editingSeasonValue, setEditingSeasonValue] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedSeasons = await fetchSeasons(token);
        setSeasons(fetchedSeasons);
        console.log("Fetched seasons:", fetchedSeasons);
      } catch (error) {
        console.error("Error fetching seasons", error);
      }
    };
    fetchData();
  }, [token]);

  const handleNewSeasonChange = (e) => {
    setNewSeason(e.target.value);
  };

  const handleCreateSeason = async () => {
    if (!newSeason) return;
    try {
      const createdSeason = await createSeason(newSeason, token);
      setSeasons([createdSeason, ...seasons]);
      setNewSeason("");
      console.log("Created season:", createdSeason);
    } catch (error) {
      console.error("Error creating season", error);
    }
  };

  const handleEditClick = (season) => {
    setEditingSeasonId(season._id);
    setEditingSeasonValue(season.season);
  };

  const handleCancelEdit = () => {
    setEditingSeasonId(null);
    setEditingSeasonValue("");
  };

  const handleSaveEdit = async () => {
    if (!editingSeasonValue) return;
    try {
      const updated = await updateSeason(
        editingSeasonId,
        { season: editingSeasonValue },
        token
      );
      setSeasons(seasons.map((s) => (s._id === updated._id ? updated : s)));
      setEditingSeasonId(null);
      setEditingSeasonValue("");
    } catch (error) {
      console.error("Error updating season", error);
    }
  };

  return (
    <div className="season-management">
      <h2>Season Management</h2>

      <div className="season-section">
        <h3>Create New Season</h3>
        <div className="input-group">
          <input
            type="text"
            value={newSeason}
            onChange={handleNewSeasonChange}
            placeholder="Enter new season"
            className="season-input"
          />
          <button onClick={handleCreateSeason} className="settings-btn">
            Create Season
          </button>
        </div>
      </div>

      <div className="season-section">
        <h3>Existing Seasons</h3>
        <ul className="season-list">
          {seasons.map((season) => (
            <li key={season._id}>
              {editingSeasonId === season._id ? (
                <div className="edit-section">
                  <input
                    type="text"
                    value={editingSeasonValue}
                    onChange={(e) => setEditingSeasonValue(e.target.value)}
                    className="season-input edit-input"
                  />
                  <button onClick={handleSaveEdit} className="settings-btn">
                    Save
                  </button>
                  <button
                    onClick={handleCancelEdit}
                    className="settings-btn btn-secondary"
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="season-item">
                  <span>{season.season}</span>
                  <button
                    onClick={() => handleEditClick(season)}
                    className="settings-btn btn-secondary"
                  >
                    Edit
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SeasonManagement;
