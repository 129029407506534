// src/components/General/Settings.js
import React, { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import SeasonManagement from "../Seasons/SeasonManagement";
import CreateReportCardTemplate from "../ReportCards/CreateReportCardTemplate";
import UserList from "../UserList";
import CreateClass from "../Classes/CreateClass";
import "./Settings.css";

const Settings = () => {
  const { userRole } = useContext(UserContext);
  const [selectedSetting, setSelectedSetting] = useState(null);

  // Render the component based on the selected setting
  const renderSettingComponent = () => {
    switch (selectedSetting) {
      case "createClass":
        return <CreateClass />;
      case "seasonManagement":
        return <SeasonManagement />;
      case "createReportTemplate":
        return <CreateReportCardTemplate />;
      case "userManagement":
        return <UserList />;
      default:
        return null;
    }
  };

  // Render the settings menu with clickable options
  const renderSettingsMenu = () => (
    <div className="settings-menu">
      <h2>Settings</h2>
      <ul>
        <li>
          <button onClick={() => setSelectedSetting("createClass")}>
            Create new Class/Lab
          </button>
          <button onClick={() => setSelectedSetting("seasonManagement")}>
            Season Management
          </button>
          <button onClick={() => setSelectedSetting("createReportTemplate")}>
            New Report Card Template
          </button>
          <button onClick={() => setSelectedSetting("userManagement")}>
            User Management
          </button>
        </li>
        {/* Add more settings options as needed */}
      </ul>
    </div>
  );

  // Handler to go back to the main settings menu
  const handleBack = () => setSelectedSetting(null);

  return (
    <div className="settings">
      {userRole === 8 ? (
        selectedSetting ? (
          <div className="selected-setting">
            <button onClick={handleBack} className="back-button">
              Back
            </button>
            {renderSettingComponent()}
          </div>
        ) : (
          renderSettingsMenu()
        )
      ) : (
        <div>
          <h2>Settings</h2>
          <p>You don't have the necessary role to view this page.</p>
        </div>
      )}
    </div>
  );
};

export default Settings;
