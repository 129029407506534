import React from "react";

const EditClassForm = ({
  editFormData,
  seasonsList,
  instructorsList,
  coursesList,
  handleEditInputChange,
  handleSaveClass,
  handleAddClassUpdate,
  removeStudentFromClass,
  addStudentToClass,
  removeClass,
  newClassUpdate,
  setNewClassUpdate,
  setShowStudentModal,
  setEditingClassId,
}) => {
  return (
    <form className="edit-class-form" onSubmit={handleSaveClass}>
      {/* Initial Fields */}
      <div className="edit-class-initial-fields">
        {/* Class Code */}
        <div className="form-group-display-class">
          <label htmlFor="class_code">Class Code</label>
          <input
            type="text"
            id="class_code"
            name="class_code"
            value={editFormData.class_code}
            onChange={handleEditInputChange}
            className="form-control-display-class"
          />
        </div>

        {/* Season */}
        <div className="form-group-display-class">
          <label htmlFor="season_ref">Season</label>
          <select
            id="season_ref"
            name="season_ref"
            value={editFormData.season_ref ? editFormData.season_ref._id : ""}
            onChange={handleEditInputChange}
            className="form-control-display-class"
          >
            <option value="">Select Season</option>
            {seasonsList.map((season) => (
              <option key={season._id} value={season._id}>
                {season.season}
              </option>
            ))}
          </select>
        </div>

        {/* Instructor */}
        <div className="form-group-display-class">
          <label htmlFor="instructor">Instructor</label>
          <select
            id="instructor"
            name="instructor"
            value={editFormData.instructor}
            onChange={handleEditInputChange}
            className="form-control-display-class"
          >
            <option value="">Select Instructor</option>
            {instructorsList.map((instructor, index) => (
              <option key={instructor._id} value={instructor._id}>
                {instructor.name}
              </option>
            ))}
          </select>
        </div>

        {/* Course */}
        <div className="form-group-display-class">
          <label htmlFor="course">Course</label>
          <select
            id="course"
            name="course"
            value={editFormData.course}
            onChange={handleEditInputChange}
            className="form-control-display-class"
          >
            <option value="">Select Course</option>
            {coursesList.map((course) => (
              <option key={course._id} value={course._id}>
                {course.name}
              </option>
            ))}
          </select>
        </div>

        {/* From Time */}
        <div className="form-group-display-class">
          <label htmlFor="from_time">From Time</label>
          <input
            type="text"
            id="from_time"
            name="from_time"
            value={editFormData.from_time}
            onChange={handleEditInputChange}
            className="form-control-display-class"
          />
        </div>

        {/* To Time */}
        <div className="form-group-display-class">
          <label htmlFor="to_time">To Time</label>
          <input
            type="text"
            id="to_time"
            name="to_time"
            value={editFormData.to_time}
            onChange={handleEditInputChange}
            className="form-control-display-class"
          />
        </div>

        {/* Start Date */}
        <div className="form-group-display-class">
          <label htmlFor="start_date">Start Date</label>
          <input
            type="date"
            id="start_date"
            name="start_date"
            value={editFormData.start_date}
            onChange={handleEditInputChange}
            className="form-control-display-class"
          />
        </div>

        {/* End Date */}
        <div className="form-group-display-class">
          <label htmlFor="end_date">End Date</label>
          <input
            type="date"
            id="end_date"
            name="end_date"
            value={editFormData.end_date}
            onChange={handleEditInputChange}
            className="form-control-display-class"
          />
        </div>
      </div>
      <hr />
      <div className="edit-form-links">
        {/* Course Link */}
        <div className="form-group-display-class-links">
          <label htmlFor="course_link">
            <strong>Course Link</strong>
          </label>
          <input
            type="text"
            id="course_link"
            name="course_link"
            value={editFormData.course_link || ""}
            onChange={handleEditInputChange}
            className="form-control-display-class-link"
          />
        </div>

        {/* Zoom Link */}
        <div className="form-group-display-class-links">
          <label htmlFor="zoom_link">
            <strong>Zoom Link</strong>
          </label>
          <input
            type="text"
            id="zoom_link"
            name="zoom_link"
            value={editFormData.zoom_link || ""}
            onChange={handleEditInputChange}
            className="form-control-display-class-link"
          />
        </div>
      </div>
      <hr />
      {/* Class Updates */}
      <div className="form-group-class-updates">
        <label htmlFor="class_updates">
          <strong>Class Updates</strong>
        </label>
        <ul>
          {editFormData.class_updates &&
            editFormData.class_updates.map((update, index) => (
              <li key={`${update.date}-${index}`}>
                {update.update} - {new Date(update.date).toLocaleString()}
              </li>
            ))}
        </ul>
        <div className="add-update-section">
          <textarea
            id="class_updates"
            name="class_updates"
            placeholder="Enter new update..."
            rows="2"
            value={newClassUpdate}
            onChange={(e) => setNewClassUpdate(e.target.value)}
            className="form-control-textarea"
          />
          <button
            type="button"
            className="btn btn-secondary add-update-button"
            onClick={handleAddClassUpdate}
          >
            Add Update
          </button>
        </div>
      </div>
      <div className="students-remove-class">
        {/* Students */}
        <div className="form-group-students">
          <div className="student-heading">
            Students
            <button
              type="button"
              className="add-student-button"
              onClick={() => setShowStudentModal(true)}
            >
              +
            </button>
          </div>
          <ul>
            {editFormData.students.map((studentItem) =>
              studentItem.student ? (
                <li key={studentItem.student._id}>
                  {studentItem.student.first_name}{" "}
                  {studentItem.student.last_name} (
                  {studentItem.student.student_num}){" - "}
                  <button
                    type="button"
                    className="remove-student-button"
                    onClick={() =>
                      removeStudentFromClass(studentItem.student._id)
                    }
                  >
                    Remove
                  </button>
                </li>
              ) : (
                <li key={studentItem._id}>Student details not available</li>
              )
            )}
          </ul>
        </div>
      </div>
      <button type="submit" className="btn btn-primary">
        Save
      </button>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => setEditingClassId(null)}
      >
        Cancel
      </button>
      <button
        type="button"
        className="remove-class-button btn btn-secondary"
        onClick={() => removeClass(editFormData._id)}
      >
        Delete Class
      </button>
    </form>
  );
};

export default EditClassForm;
