import React from "react";
import { FaRegEye } from "react-icons/fa";
import "./StudentSearchResults.css";

const StudentSearchResults = ({ students, onStudentSelect }) => {
  return (
    <div className="student-search-results">
      {students.map((student) => (
        <div key={student._id} className="student-row">
          <div className="student-info">
            <span className="student-full-name">
              {student.first_name} {student.last_name}
            </span>
            <span className="student-number">{student.student_num}</span>
          </div>
          <button
            className="view-button"
            onClick={() => onStudentSelect(student.student_num)}
          >
            <FaRegEye />
          </button>
        </div>
      ))}
    </div>
  );
};

export default StudentSearchResults;
