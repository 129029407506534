// src/api/seasonApi.js
const API_URL = process.env.REACT_APP_API_URL;

// Get all seasons
export const fetchSeasons = async (token) => {
  const response = await fetch(`${API_URL}/api/seasons`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`Error fetching seasons: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

// Create a new season
export const createSeason = async (seasonName, token) => {
  const response = await fetch(`${API_URL}/api/seasons/new`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ season: seasonName }),
  });

  if (!response.ok) {
    throw new Error(`Error creating season: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

// Update an existing season
export const updateSeason = async (id, seasonData, token) => {
  const response = await fetch(`${API_URL}/api/seasons/update/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(seasonData),
  });

  if (!response.ok) {
    throw new Error(`Error updating season: ${response.status}`);
  }

  const data = await response.json();
  return data;
};
