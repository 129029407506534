import React, { useState, useEffect } from "react";
import { fetchCourses } from "../api/courseApi"; // Assuming fetchCourses is implemented to get courses from the backend
import ExportCSV from "./General/ExportCSV";
import "./MarketingQuery.css"; // Assuming you have a CSS file to style the page
const API_URL = process.env.REACT_APP_API_URL;

const MarketingQuery = () => {
  const [courses, setCourses] = useState([]);
  const [coursesTaken, setCoursesTaken] = useState([]);
  const [coursesNotTaken, setCoursesNotTaken] = useState([]);
  const [nextRecommendedCourse, setNextRecommendedCourse] = useState("");
  const [nextRecommendedLevel, setNextRecommendedLevel] = useState("");
  const [levelTaken, setLevelTaken] = useState("");
  const [levelNotTaken, setLevelNotTaken] = useState("");
  const [students, setStudents] = useState([]); // Add state to store query results
  const [sampleData, setSampleData] = useState([]);
  const [loader, setLoader] = useState(false);
  const levels = [
    "FREE TRIAL",
    "Introductory",
    "Beginner",
    "Beginner+",
    "Developing",
    "Developing+",
    "Intermediate",
    "Advanced",
    "Elite",
    "Open-Ended",
  ];

  useEffect(() => {
    // Fetch all courses from the backend when the component mounts
    fetchCourses()
      .then((data) => {
        setCourses(data);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  }, []);

  const handleSubmit = async () => {
    setLoader(true);
    const queryData = {
      courses_taken: coursesTaken,
      courses_not_taken: coursesNotTaken,
      next_recommended: nextRecommendedCourse,
      next_recommended_level: nextRecommendedLevel,
      level_taken: levelTaken,
      level_not_taken: levelNotTaken,
    };

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authentication token is missing");
      }

      const response = await fetch(`${API_URL}/api/marketing-query`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(queryData),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch students");
      }

      const students = await response.json();

      setStudents(students); // Set the updated students to state

      const formattedData = students.map((student) => ({
        guid: student._id,
        student_name: `${student.first_name} ${student.last_name}`,
        customer_primary_name: `${student.customer.primary_first_name} ${student.customer.primary_last_name}`,
        customer_secondary_name: `${student.customer.sec_first_name || ""} ${
          student.customer.sec_last_name || ""
        }`,
        customer_number: student.customer_num,
        student_number: student.student_num,
        customer_email: student.customer.email_address,
        customer_secondary_email: student.customer.sec_email_address || "",
      }));

      setSampleData(formattedData);
      setLoader(false);
    } catch (error) {
      console.error("Error executing marketing query:", error);
    }
  };

  return (
    <div className="marketing-query-container">
      <h2 className="marketing-query">Marketing Queries</h2>

      <div className="filter-section-courses">
        <label>Courses Taken:</label>
        <select
          multiple
          value={coursesTaken}
          onChange={(e) =>
            setCoursesTaken(
              Array.from(e.target.selectedOptions, (option) => option.value)
            )
          }
        >
          {courses.map((course) => (
            <option key={course._id} value={course._id}>
              {course.name}
            </option>
          ))}
        </select>
      </div>

      <div className="filter-section-courses">
        <label>Courses Not Taken:</label>
        <select
          multiple
          value={coursesNotTaken}
          onChange={(e) =>
            setCoursesNotTaken(
              Array.from(e.target.selectedOptions, (option) => option.value)
            )
          }
        >
          {courses.map((course) => (
            <option key={course._id} value={course._id}>
              {course.name}
            </option>
          ))}
        </select>
      </div>

      <div className="filter-section-other">
        <label>Next Recommended Course:</label>
        <select
          value={nextRecommendedCourse}
          onChange={(e) => setNextRecommendedCourse(e.target.value)}
        >
          <option value="">Select a course</option>
          {courses.map((course) => (
            <option key={course._id} value={course._id}>
              {course.name}
            </option>
          ))}
        </select>
      </div>

      <div className="filter-section-other">
        <label>Next Recommended Level:</label>
        <select
          value={nextRecommendedLevel}
          onChange={(e) => setNextRecommendedLevel(e.target.value)}
        >
          <option value="">Select a level</option>
          {levels.map((level, index) => (
            <option key={index} value={level}>
              {level}
            </option>
          ))}
        </select>
      </div>

      <div className="filter-section-other">
        <label>Level Taken:</label>
        <select
          value={levelTaken}
          onChange={(e) => setLevelTaken(e.target.value)}
        >
          <option value="">Select a level</option>
          {levels.map((level, index) => (
            <option key={index} value={level}>
              {level}
            </option>
          ))}
        </select>
      </div>

      <div className="filter-section-other">
        <label>Level Not Taken:</label>
        <select
          value={levelNotTaken}
          onChange={(e) => setLevelNotTaken(e.target.value)}
        >
          <option value="">Select a level</option>
          {levels.map((level, index) => (
            <option key={index} value={level}>
              {level}
            </option>
          ))}
        </select>
      </div>

      <button className="marketing-query-button" onClick={handleSubmit}>
        Run Query
      </button>

      {loader && (
        <div
          aria-label="Orange and tan hamster running in a metal wheel"
          role="img"
          class="wheel-and-hamster"
        >
          <div class="wheel"></div>
          <div class="hamster">
            <div class="hamster__body">
              <div class="hamster__head">
                <div class="hamster__ear"></div>
                <div class="hamster__eye"></div>
                <div class="hamster__nose"></div>
              </div>
              <div class="hamster__limb hamster__limb--fr"></div>
              <div class="hamster__limb hamster__limb--fl"></div>
              <div class="hamster__limb hamster__limb--br"></div>
              <div class="hamster__limb hamster__limb--bl"></div>
              <div class="hamster__tail"></div>
            </div>
          </div>
          <div class="spoke"></div>
        </div>
      )}
      <div className="results-section">
        <h3>Matching Students</h3>
        <p>Total Students Found: {students.length}</p>{" "}
        {/* Display total count */}
        {students.length > 0 ? (
          <div>
            {sampleData.length > 0 ? (
              <ExportCSV data={sampleData} filename="students.csv" />
            ) : (
              <p>No data to export</p>
            )}

            <ul>
              {students.map((student) => (
                <li key={student._id}>
                  {student.first_name} {student.last_name} -{" "}
                  {student.customer.primary_first_name}{" "}
                  {student.customer.primary_last_name}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p>No students match the selected criteria.</p>
        )}
      </div>
    </div>
  );
};

export default MarketingQuery;
