import React, { useState, useEffect, useContext } from "react";
import {
  fetchInstructors,
  fetchCourses,
  submitClassData,
  searchStudents, // API to search students
} from "../../api/classApi"; // Import API functions
import { UserContext } from "../../context/UserContext";

import { fetchSeasons } from "../../api/seasonApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StudentSearchModal from "../Students/StudentSearchModal"; // Import the modal component

import "./CreateClass.css";

const CreateClassForm = () => {
  const { userID } = useContext(UserContext);
  const [instructorsList, setInstructorsList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [seasonOptions, setSeasonOptions] = useState([]);

  const [formData, setFormData] = useState({
    students: [],
    instructor: "",
    course: "",
    start_date: "",
    end_date: "",
    season: "",
    season_ref: "",
    class_code: "",
    legacy: false,
    from_time: "",
    to_time: "",
    course_link: "",
    zoom_link: "",
    class_updates: [],
  });

  const token = localStorage.getItem("token");

  const [newClassUpdate, setNewClassUpdate] = useState("");

  // Fetch all necessary data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const instructors = await fetchInstructors(token);
        const courses = await fetchCourses(token);
        const seasons = await fetchSeasons(token);
        setInstructorsList(
          instructors.sort((a, b) => a.name.localeCompare(b.name))
        );
        setCoursesList(courses);
        setSeasonOptions(seasons);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [token]);

  // Handle form field changes (Instructor, Course, Dates, etc.)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSeasonChange = (e) => {
    const selectedId = e.target.value;
    const selectedSeason = seasonOptions.find((s) => s._id === selectedId);
    if (selectedSeason) {
      setFormData({
        ...formData,
        season: selectedSeason.season, // The season string from the season document
        season_ref: selectedSeason._id, // The reference to the Season document
      });
    } else {
      setFormData({
        ...formData,
        season: "",
        season_ref: "",
      });
    }
  };

  // Add student to the class
  const addStudentToClass = (student) => {
    setFormData({ ...formData, students: [...formData.students, student] });
    setShowStudentModal(false); // Close modal
  };

  // Remove student from the class
  const removeStudentFromClass = (studentId) => {
    setFormData({
      ...formData,
      students: formData.students.filter(
        (student) => student._id !== studentId
      ),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await submitClassData(formData, token);
      console.log("Class created successfully:", response);
      toast.success("Class created successfully!");
    } catch (error) {
      console.error("Error creating class:", error);
      toast.error("Error creating class. Please try again.");
    }
  };

  // Generate time options in 15-minute intervals with AM/PM format
  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const period = hour < 12 ? "AM" : "PM";
        const formattedHour = (hour % 12 || 12).toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        times.push(`${formattedHour}:${formattedMinute} ${period}`);
      }
    }
    return times;
  };

  const timeOptions = generateTimeOptions();

  return (
    <>
      <form className="create-class-form" onSubmit={handleSubmit}>
        {/* Instructor and Class Code on the same row */}
        <div className="form-group-row large-small">
          <div className="form-group">
            <label htmlFor="instructor">Instructor</label>
            <select
              id="instructor"
              name="instructor"
              value={formData.instructor}
              onChange={handleChange}
              className="form-control"
            >
              <option value="">Select Instructor</option>
              {instructorsList.map((instructor) => (
                <option key={instructor._id} value={instructor._id}>
                  {instructor.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="class_code">Class Code</label>
            <input
              type="text"
              id="class_code"
              name="class_code"
              value={formData.class_code}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        </div>

        {/* Season, Start Date, End Date */}
        <div className="form-group-row">
          <div className="form-group">
            <label htmlFor="season">Season</label>
            <select
              id="season"
              name="season"
              value={formData.season_ref} // value is season_ref from formData
              onChange={handleSeasonChange}
              className="form-control"
            >
              <option value="">Select Season</option>
              {seasonOptions.map((season) => (
                <option key={season._id} value={season._id}>
                  {season.season}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="start_date">Start Date</label>
            <input
              type="date"
              id="start_date"
              name="start_date"
              value={formData.start_date}
              onChange={handleChange}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label htmlFor="end_date">End Date</label>
            <input
              type="date"
              id="end_date"
              name="end_date"
              value={formData.end_date}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        </div>

        {/* Course */}
        <div className="form-group">
          <label htmlFor="course">Course</label>
          <select
            id="course"
            name="course"
            value={formData.course}
            onChange={handleChange}
            className="form-control"
          >
            <option value="">Select Course</option>
            {coursesList.map((course) => (
              <option key={course._id} value={course._id}>
                {course.name}
              </option>
            ))}
          </select>
        </div>

        {/* From Time and To Time */}
        <div className="form-group-row">
          <div className="form-group">
            <label htmlFor="from_time">From Time</label>
            <select
              id="from_time"
              name="from_time"
              value={formData.from_time}
              onChange={handleChange}
              className="form-control"
            >
              <option value="">Select Start Time</option>
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="to_time">To Time</label>
            <select
              id="to_time"
              name="to_time"
              value={formData.to_time}
              onChange={handleChange}
              className="form-control"
            >
              <option value="">Select End Time</option>
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Course Link */}
        <div className="form-group">
          <label htmlFor="course_link">Course Link</label>
          <input
            type="text"
            id="course_link"
            name="course_link"
            value={formData.course_link}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        {/* Zoom Link */}
        <div className="form-group">
          <label htmlFor="zoom_link">Zoom Link</label>
          <input
            type="text"
            id="zoom_link"
            name="zoom_link"
            value={formData.zoom_link}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        {/* Class Updates */}
        <div className="form-group">
          <label htmlFor="class_updates">(Optional) Notes for the class</label>
          <input
            type="text"
            id="class_updates"
            name="class_updates"
            value={newClassUpdate}
            onChange={(e) => {
              setNewClassUpdate(e.target.value);
              setFormData({
                ...formData,
                class_updates: [
                  { update: e.target.value, date: new Date(), author: userID },
                ],
              });
            }}
            className="form-control"
          />
        </div>

        {/* Students */}
        <div className="form-group">
          <h3>
            Students
            <button
              type="button"
              className="add-student-button"
              onClick={() => setShowStudentModal(true)}
            >
              +
            </button>
          </h3>
          <ul>
            {formData.students.map((student) => (
              <li key={student._id}>
                {student.first_name} {student.last_name} ({student.student_num})
                <button
                  type="button"
                  className="remove-student-button"
                  onClick={() => removeStudentFromClass(student._id)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Submit Button */}
        <button type="submit" className="btn btn-primary">
          Create Class
        </button>
      </form>

      <ToastContainer />

      {/* Reusable Student Search Modal */}
      {showStudentModal && (
        <StudentSearchModal
          onClose={() => setShowStudentModal(false)}
          onAddStudent={addStudentToClass}
          searchStudents={searchStudents}
        />
      )}
    </>
  );
};

export default CreateClassForm;
