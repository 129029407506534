export function FieldDisplay({ label, value, formatValue }) {
  // Check for null, undefined or empty string
  if (value === null || value === undefined || value === "" || value === " ") {
    return null;
  }
  return (
    <p>
      <strong>{label}:</strong> {formatValue ? formatValue(value) : value}
    </p>
  );
}
