// src/components/CustomerView.js
import React, { useEffect, useState } from "react";
import StudentView from "../Students/StudentView";
import ReusableModal from "../General/ReusableModal";
import { FaRegEye } from "react-icons/fa";
import { fetchStudentsByCustomerNum } from "../../api/studentApi";
import { FieldDisplay } from "../General/Utilities";
import "./CustomerView.css";

const CustomerView = ({ customer, onBack, onEdit }) => {
  // const [customer, setCustomer] = useState(null);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    const fetchCustomerAndStudents = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Authentication token is missing");
        }
        // Fetch customer details
        // const responseCustomer = await fetch(
        //   `${API_URL}/api/customers/find?customerID=${customerID}`,
        //   {
        //     method: "GET",
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //       "Content-Type": "application/json",
        //     },
        //   }
        // );
        // if (!responseCustomer.ok) {
        //   throw new Error("Customer not found");
        // }
        // const customerData = await responseCustomer.json();
        // setCustomer(customerData);
        // console.log(customerData);
        // Fetch student details linked to this customer
        const responseStudents = await fetchStudentsByCustomerNum(
          customer.CustomerID
        );

        setStudents(responseStudents);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (customer) {
      fetchCustomerAndStudents();
    }
  }, [customer]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!customer) {
    return null;
  }
  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };
  const handleViewStudentAdminClick = (studentNum) => {
    setModalContent(() => (
      <StudentView
        studentNum={studentNum}
        showParentLink={false}
        onClose={() => {
          handleModalClose();
        }}
      />
    ));
    setIsModalOpen(true);
  };

  function buildAddress(customer) {
    // Create an array of address parts
    const addressParts = [
      customer.street_num,
      customer.street_name,
      customer.city,
      customer.prov_code,
      customer.postal_code,
      customer.country,
    ];

    return addressParts.filter(Boolean).join(", ");
  }

  function CustomerAddress() {
    const address = buildAddress(customer);

    // Only render if address is not empty
    if (!address) {
      return null;
    }

    return (
      <p>
        <strong>Address:</strong> {address}
      </p>
    );
  }

  function CustomerDetails() {
    return (
      <div className="customer-info">
        <h2>Customer Details</h2>
        <FieldDisplay label="Customer ID" value={customer.CustomerID} />
        <FieldDisplay
          label="Primary Name"
          value={`${customer.primary_first_name} ${customer.primary_last_name}`}
        />
        <FieldDisplay label="Customer Relationship" value={customer.cust_rel} />
        <FieldDisplay
          label="Secondary Contact"
          value={`${customer.sec_first_name} ${customer.sec_last_name}`}
        />
        <FieldDisplay label="Secondary Relationship" value={customer.sec_rel} />
        <FieldDisplay label="Home Phone" value={customer.home_phone} />
        <FieldDisplay label="Cell Phone" value={customer.cell_phone} />
        <FieldDisplay label="Secondary Phone" value={customer.sec_phone} />
        <FieldDisplay label="Email" value={customer.email_address} />
        <FieldDisplay
          label="Secondary Email"
          value={customer.sec_email_address}
        />

        <CustomerAddress customer={customer} />

        <FieldDisplay
          label="Date Contacted"
          value={customer.date_contact}
          formatValue={(date) => new Date(date).toLocaleDateString()}
        />
        <FieldDisplay label="How Found" value={customer.how_found} />
        <FieldDisplay label="Rep ID" value={customer.rep_id} />
        <FieldDisplay
          label="Unsubscribed"
          value={customer.unsub ? "Yes" : "No"}
        />
        <FieldDisplay label="Comments" value={customer.comment} />
      </div>
    );
  }

  function StudentDetails({ student, onViewStudent }) {
    return (
      <div className="row student-details">
        <div className="col-7">
          <strong>Student Name:</strong> {student.first_name}{" "}
          {student.last_name}
        </div>
        <div className="col-4">
          <strong>Student Number:</strong> {student.student_num}
        </div>
        <div className="col-1">
          <FaRegEye
            className="action-icon"
            style={{ marginLeft: "10px", marginRight: "0px" }}
            title="View Student"
            onClick={() => onViewStudent(student.student_num)}
          />
        </div>
        <hr />
      </div>
    );
  }

  return (
    <div className="customer-view-wrapper">
      {onEdit && (
        <>
          <button className="customer-view-back-button" onClick={onBack}>
            Back
          </button>
          <button
            className="customer-view-edit-button"
            onClick={() => onEdit(customer)}
          >
            Edit
          </button>
        </>
      )}
      <div className="customer-view-container">
        {CustomerDetails()}

        {/* Student Information */}
        {students.length > 0 && (
          <div className="student-info">
            <h2>Student Details</h2>
            {students.map((student) => (
              <StudentDetails
                key={student._id}
                student={student}
                onViewStudent={handleViewStudentAdminClick}
              />
            ))}
          </div>
        )}
      </div>
      {modalContent != null && (
        <ReusableModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          content={modalContent}
        />
      )}
    </div>
  );
};

export default CustomerView;
