import React from "react";

const ExportCSV = ({ data, filename }) => {
  const handleDownload = () => {
    // Convert data to CSV
    const csvContent = [
      Object.keys(data[0]).join(","), // Add header row (keys of the first object)
      ...data.map((row) => Object.values(row).join(",")), // Add rows (values of each object)
    ].join("\n");

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename || "data.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return <button onClick={handleDownload}>Download CSV</button>;
};

export default ExportCSV;
