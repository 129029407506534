import React, { useState } from "react";
import "./ClassNoteRow.css";

const ClassNoteRow = ({ classUpdate, handleEditNote, userID }) => {
  const isEditable = classUpdate.author && classUpdate.author._id === userID;
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(classUpdate.update);

  const handleClick = () => {
    if (isEditable && !isEditing) {
      setIsEditing(true);
    }
  };

  const handleSave = () => {
    const updatedNote = { ...classUpdate, update: editedText };
    handleEditNote(updatedNote);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedText(classUpdate.update);
    setIsEditing(false);
  };

  return (
    <div className="row">
      <div
        className="col-2"
        style={{
          borderStyle: "solid",
          borderRadius: "15px",
          borderColor: "#D3E1E3",
          borderWidth: "1px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          minHeight: "50px",
          maxHeight: "100px",
          alignSelf: "center",
          backgroundColor: "#F0F8FF",
        }}
      >
        <strong style={{ fontSize: "12px" }}>{classUpdate.author.name}</strong>
        <span style={{ fontSize: "12px" }}>
          {new Date(classUpdate.date).toISOString().split("T")[0]}
        </span>
      </div>

      <div
        className="col-10"
        style={{
          borderStyle: "solid",
          borderRadius: "15px",
          borderColor: "#D3E1E3",
          backgroundColor: "rgb(243, 249, 255)",
          borderWidth: "1px 0 1px 2px",
          fontSize: "14px",
          paddingTop: "5px",
          paddingBottom: "7px",
          whiteSpace: "pre-wrap",
        }}
      >
        {isEditing ? (
          <div className="edit-note-container">
            <textarea
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              style={{ width: "100%", fontSize: "14px", padding: "5px" }}
            />
            <div className="edit-note-buttons" style={{ marginTop: "5px" }}>
              <button onClick={handleSave}>Save</button>
              <button onClick={handleCancel} style={{ marginLeft: "10px" }}>
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div
            className={`note-update ${isEditable ? "editable-note" : ""}`}
            title={isEditable ? "Edit Note" : ""}
            onClick={handleClick}
          >
            {classUpdate.update}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassNoteRow;
